import { createTheme } from '@mui/material/styles';

import componentStyleOverrides from './compStyleOverride';
import themePalette from './palette';
import themeTypography from './typography';
import { colors } from './colors';

const theme = () => {
  const themeOption = {
    colors: colors,
    heading: colors.grey900,
    paper: colors.paper,
    backgroundDefault: colors.paper,
    background: colors.primaryLight,
    darkTextPrimary: colors.grey700,
    darkTextSecondary: colors.grey500,
    textDark: colors.grey900,
    menuSelected: colors.secondaryDark,
    menuSelectedBack: colors.secondaryLight,
    divider: colors.grey200,
    customization:{
      fontFamily: [
        //     'Roboto', // The font name you added
        //     '"Playfair Display"',    
        //     '"Helvetica Neue"',
        //     'Arial',
          //"Merriweather",
          //"Bodoni Moda",
          //"Prata",
          "Wix Madefor Text",
          //"Poiret One",
          'sans-serif'
        ].join(','),
    }
  };

  const themeOptions = {
    breakpoints: {
      values: {
        xs: 0,
        sm: 360,
        md: 900,
        lg: 1200,
        xl: 1536,
      },
    },
    direction: 'ltr',
    palette: themePalette(themeOption),
    mixins: {
      toolbar: {
        minHeight: '48px',
        padding: '16px',
        '@media (min-width: 600px)': {
          minHeight: '48px',
        },
      },
    },
    typography: themeTypography(themeOption),
  };

  const themes = createTheme(themeOptions);
  themes.components = componentStyleOverrides(themeOption);

  return themes;
};

export default theme;

import { Box, Typography, Link, Stack } from "@mui/material";
import { EmailOutlined, Phone, Sms } from "@mui/icons-material";
import { useContext } from "react";
import { StoreContext } from "layout/HomeLayout";
import { snsLink } from "layout/HomeLayout/footer";

const Contact = () => {
  const { store } = useContext(StoreContext); 
  return (
    <Box display='block' sx={{ textAlign:'center', p:'2em'}}>
      <Box>
        <Typography variant="h1" sx={{ color:'#4A2E1C', py:'1em' }}>Contact us</Typography>
        <Typography variant="h4" sx={{ lineHeight:'1.5em', pb:'1em'}}>
          {store.address}
        </Typography>
        <Stack sx={{ textAlign:'center', width:'100%' }}>
          <Link href = {`mailto:${store.email}`} color='inherit' underline="none" target='_blank'
            sx={{display:'flex', alignItems:'center', justifyContent:'center', pb:'1em' }}>
            <EmailOutlined/><Typography variant="h4">&nbsp;{store.email}</Typography>
          </Link>
          {/* <Link href = {`tel:${store.phone}`} color='inherit' underline="none" target='_blank' 
            sx={{display:'flex', alignItems:'center', justifyContent:'center', pb:'1em' }}>
            <Phone/><Typography variant="h4">&nbsp;{store.phone}</Typography>
          </Link> */}
          {/* <Link href = {`sms:${store.mobile}`} color='inherit' underline="none" target='_blank' 
            sx={{display:'flex', alignItems:'center', justifyContent:'center', pb:'1em' }}>
            <Sms/><Typography variant="h4">&nbsp;{store.mobile}</Typography>
          </Link>   */}
          <Link href = {`tel:${store.mobile}`} color='inherit' underline="none" target='_blank' 
            sx={{display:'flex', alignItems:'center', justifyContent:'center', pb:'1em' }}>
            <Sms/><Typography variant="h4">&nbsp;{store.mobile}</Typography>
          </Link> 
          {/* <Box sx={{display:'flex', alignItems:'center', justifyContent:'center'}}>
            <Link href = {`sms:${store.mobile}`} color='inherit' underline="none" target='_blank'>
              <Sms/>
            </Link> 
            <Link href = {`tel:${store.mobile}`} color='inherit' underline="none" target='_blank'>
              <Typography variant="h4">&nbsp;{store.mobile}</Typography>
            </Link>
          </Box> */}
        </Stack>
      </Box>
      <Box display='flex' sx={{ py:'2em', alignItems:'center', justifyContent:'center'  }}>
        { snsLink(store.sns, '4em') }&nbsp;{ snsLink(store.sns2, '4em') }
      </Box>
      <Box sx={{ width: '100%', overflow: 'hidden' }}>
        <iframe 
          src={store.map}
          width="100%" 
          height="300" 
          style={{border:0}} 
          allowFullScreen="" 
          loading="lazy" 
          referrerPolicy="no-referrer-when-downgrade"
          sandbox="allow-forms allow-scripts"
        ></iframe>
      </Box>
    </Box>
  )
}

export default Contact;
// config
import { path } from 'config/path';
// project imports
import HomeLayout from 'layout/HomeLayout';
import Home from 'pages/home/Home';
//import Menupage from 'pages/home/Menu';
//import Contact from 'pages/home/Contact';
//import About from 'pages/home/About';
//import Privacy from 'pages/home/Privacy';
import Terms from 'pages/home/Terms';
import StaffInfo from 'pages/home/StaffInfo';
//import ProductInfo from 'pages/home/ProductInfo';
//import Checkout from 'pages/home/Checkout';
import Staff from 'pages/home/Staff';
import Price from 'pages/home/Price';

const HomeRoutes = {
  path: path.urls.default,
  element: <HomeLayout />,
  children: [
    {
      path: path.urls.default,
      element: <Home />,
    },
    {
      path: path.urls.home,
      element: <Home />,
    },
    // {
    //   path: path.urls.about,
    //   element: <About />,
    // },
    // {
    //   path: path.urls.menu,
    //   element: <Menupage />,
    // },
    {
      path: path.urls.price,
      element: <Price />,
    },
    {
      path: path.urls.staff,
      element: <Staff />,
    },
    // {
    //   path: `${path.urls.menu}/:category`,
    //   element: <Menupage />,
    // },
    {
      path: `${path.urls.staffInfo}/:id`,
      element: <StaffInfo />,
    },
    // {
    //   path: `${path.urls.priceInfo}/:id`,
    //   element: <StaffInfo />,
    // },
    // {
    //   path: `${path.urls.productInfo}/:id`,
    //   element: <ProductInfo />,
    // },
    // {
    //   path: path.urls.checkout,
    //   element: <Checkout />,
    // },
    // {
    //   path: path.urls.contact,
    //   element: <Contact />,
    // },
    // {
    //   path: path.urls.privacy,
    //   element: <Privacy />,
    // },
    {
      path: path.urls.terms,
      element: <Terms />,
    },
  ],
};

export default HomeRoutes;

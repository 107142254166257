import { Outlet } from 'react-router-dom';
import { Container, Box } from '@mui/material';
import Footer from './footer';
import Header from './header';
import { useTheme } from '@mui/material/styles';
import { createContext, useEffect, useState } from 'react';
import { path } from 'config/path';
import { createTheme } from '@mui/material/styles';
import themeTypography from 'theme/typography';
import { colors } from 'theme/colors';
import { ThemeProvider } from '@mui/system';

export const StoreContext = createContext(null);

const HomeLayout = () => {
  const baseTheme = useTheme();
  const [cart, setCart] = useState(localStorage.getItem("choice-cart") ? JSON.parse(localStorage.getItem("choice-cart")) : []);
  const [allmenu, setAllmenu] = useState([]);
  const [store, setStore] = useState({});

  const themeOption = {
    colors: colors,
    heading: colors.grey900,
    paper: colors.paper,
    backgroundDefault: colors.paper,
    background: colors.primaryLight,
    darkTextPrimary: colors.grey700,
    darkTextSecondary: colors.grey500,
    textDark: colors.grey900,
    menuSelected: colors.secondaryDark,
    menuSelectedBack: colors.secondaryLight,
    divider: colors.grey200,
    customization: {
      fontFamily: [
        //"Merriweather",
        //"Bodoni Moda",
        //"Prata",
        //"Wix Madefor Text",
        "Poiret One",
        'sans-serif'
      ].join(','),
    }
  };

  const customTheme = createTheme({
    ...baseTheme,
    typography: themeTypography(themeOption),
  });

  useEffect(() => {
    async function getMenu() {
      let res = await fetch(`${path.ciHost}/index.php/api/v1/store/` + process.env.REACT_APP_STOREID);
      const store = await res.json();
      //console.log(store);
      setStore(store);
      res = await fetch(`${path.ciHost}/index.php/api/v1/product/` + store.id);
      const products = await res.json();
      //console.log(staffs);
      //setAllmenu(staffs);
      const categories = new Map();
      products.map((product) => {
        if (!categories.get(product.category)) {
          categories.set(product.category, new Map());
        }
        const subcat = categories.get(product.category);
        if (!subcat.get(product.subcategory)) {
          subcat.set(product.subcategory, []);
        }
        subcat.get(product.subcategory).push(product);
      });
      //console.log(categories);
      setAllmenu(categories);
    }
    getMenu();
  }, []);

  useEffect(() => {
    localStorage.setItem("choice-cart", JSON.stringify(cart));
  }, [cart]);

  return (
    <StoreContext.Provider value={{ store, allmenu, cart, setCart }}>
      <ThemeProvider theme={customTheme}>
        <Box id='home-layout' width='100%' sx={{ fontSize: { xs: '9px', sm: '10px' } }}>
          <Header />
          <Container maxWidth="lg" sx={{ py: 0, mt: { xs: '7.2em', md: '10em' }, px: { xs: 0, sm: 0, md: 5 } }}>
            <Outlet />
          </Container>
          <Footer />
        </Box>
      </ThemeProvider>
    </StoreContext.Provider>
  )
};

export default HomeLayout;
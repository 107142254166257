import { useRoutes } from 'react-router-dom';
// routes
import AdminRoutes from './Admin';
import MainRoutes from './Main';
import UserRoutes from './User';
import SettingRoutes from './Setting';
import AuthenticationRoutes from './Authentication';
import HomeRoutes from './HomeRoutes';

const Router = () => {
  return useRoutes([
    AdminRoutes,
    HomeRoutes,
    MainRoutes,
    UserRoutes,
    SettingRoutes,
    AuthenticationRoutes,
  ]);
};

export default Router;

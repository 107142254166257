import { Accordion, AccordionDetails, AccordionSummary, Box, Breadcrumbs, Stack, Typography } from "@mui/material";
import { Link, useParams } from "react-router-dom";
import { useContext, useState, useEffect } from "react";
import { ExpandMore } from "@mui/icons-material";
import { StoreContext } from "../../layout/HomeLayout/index";
import { path } from "config/path";

// PRODUCT INFO
const StaffInfo = () => {
	// HOOKS
	const params = useParams();
	const { store } = useContext(StoreContext);

	// DATA
	const [products, setProducts] = useState([]);
	const [pidx, setPidx] = useState(0);

	useEffect(() => {
		async function getMenu() {
			const res = await fetch(`${path.ciHost}/index.php/api/v1/product/id/` + params.id);
			const products = await res.json();
			setPidx(0);
			setProducts(products);
		}
		getMenu();
	}, [params.id]);

	// RENDER
	return products.length > 0 && products[pidx] ? (
		<Box>
			{/* BreadCrumbs */}
			<Breadcrumbs sx={{ pt: 2, px: 2 }}>
				{/* <Link to="/">Home</Link> */}
				<Link to={`/staff`}><Typography variant="h5">Staffs</Typography></Link>
				<Typography variant="h5" sx={{ textTransform: "capitalize" }}>{products[pidx].name}</Typography>
			</Breadcrumbs>
			<Stack sx={{ p: 2 }} spacing={1}>
				{/* Title */}
				<Typography variant="h2">{products[pidx].name}</Typography>				
				<Typography variant="h3" fontWeight={"normal"} sx={{ textTransform:'capitalize'}}>
					{products[pidx].subcategory}
				</Typography>
			</Stack>
			<Stack direction={{xs:'colume', md:'row'}} sx={{ alignItems:'center', justifyContent:'center' }}>
				{/* Image */}
				<Box display='flex' sx={{ px: 2, maxWidth: 400, width: '80%' }}>
					<img
						src={products[pidx].image ? `${path.imageServer}${path.imageDirectory}/${store.key}/${products[pidx].image}` : `${path.basename}/images/user-circle.svg`}
						alt={products[pidx].name}
						style={{ width: "100%", height: "auto" }}
					/>
				</Box>
				<Stack spacing={2} sx={{ mt: 2 }}>
					<Stack>
						{/* Description */}
						{products[pidx].note && (
							<Accordion disableGutters square defaultExpanded>
								<AccordionSummary expandIcon={<ExpandMore />}>
									<Typography variant="h4">Description</Typography>
								</AccordionSummary>
								<AccordionDetails>
									<Typography sx={{ textAlign: "justify" }} variant="body1">
										{products[pidx].note}
									</Typography>
								</AccordionDetails>
							</Accordion>
						)}						
					</Stack>
				</Stack>
			</Stack>
		</Box >
	) : (
		<>
			<h1>Loading...</h1>
		</>
	);
};

export default StaffInfo;
